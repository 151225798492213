<template>
  <v-dialog
    v-model="state"
    max-width="350"
    overlay-color="#fff"
    overlay-opacity="0.8"
    persistent
  >
    <v-card>
      <v-card-title class="ft text-md ml-n2">Bulk Upload</v-card-title>

      <v-container class="mt-n4">
        <span class="ml-1 ft text-sm mb-2"
          >Upload a <strong>csv</strong> or <strong>excel</strong> file</span
        >
        <div class="d-flex flex-row flex-full">
          <v-file-input
            accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            label="File input"
            show-size
          ></v-file-input>
        </div>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          class="text-capitalize text-sm ft"
          color="blue accent-3"
          text
          @click="action({ state: false, idx: 'bulk' })"
        >
          Cancel
        </v-btn>

        <v-btn
          class="text-capitalize text-sm ft"
          color="blue accent-3"
          text
          @click="action({ state: false, idx: 2 })"
        >
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BulkUpload",
  props: {
    state: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    action(state) {
      this.$emit("actions", state);
    }
  }
};
</script>

<style scoped></style>
